import React, { ReactElement, ChangeEvent, KeyboardEvent } from "react";
import { useRef, useEffect } from 'react';

/**
 * An input field with bootstrap styling for use in a form.
 * Supplies an optional label, and works with different input types.
 * 
 * @param id Unique identifier for this input field
 * @param name Unique name for this input field
 * @param label If set, a <label> element is used to display the text in @label
 * @param type Input type. Defaults to text
 * @param value useState getter managed by parent
 * @param setValue useState setter managed by parent
 * @param autoFocus If true, focus this input field when the component loads
 * @param onEnter Callback to run when the user pressed Enter within this field
*/
export default function InputField({
	id="",
	name="",
	label="",
	type="text",
	value,
	setValue,
	autoFocus=false,
	onEnter = () => {}
}: IInputField){

	useEffect(() => {
		if (autoFocus) inputReference.current?.focus();
	}, [])

	const inputReference = useRef<HTMLInputElement | null>(null);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value);
	const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter'){
			onEnter();
		}
	}

	var span: ReactElement;
	var divClass = "input-group mb-3";
	if (name.length > 0){
		span = (<span className="input-group-text">{name}</span>);
	}else if (label.length > 0){
		divClass = "mb-3";
		span = (<label className="small">{label}</label>);
	}else{
		span = (<></>);
	}

	return (
		<div className={divClass}>
			{span}
			<input
				key={id}
				id={id}
				type={type}
				className="form-control"
				value={value}
				onChange={handleChange}
				onKeyPress={onKeyPress}
				ref={inputReference}
				/>
		</div>
	);

}

interface IInputField{
	id: string;
	name: string;
	label: string;
	type: string;
	value: string;
	setValue: (newValue: string) => void;
	autoFocus: boolean,
	onEnter: () => void
}