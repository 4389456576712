import { useState } from 'react';

export default function AlertViewModel(){

  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  
  const show = (msg, title) => {
    if (typeof title === 'undefined') title = '';
    setTitle(title);
    setMessage(msg);
  };

  return {
  	title, setTitle,
  	message, setMessage,
    show
  };

}
