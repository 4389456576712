import { useEffect, useState } from 'react';
import { DateTime } from "luxon";
import AjaxUtil from '../../common/js/AjaxUtil.ts';

export default function DriverLogsListViewModel(){

  const moment = DateTime.now();
  const start = moment.toFormat('yyyy-MM-dd HH:00:00');
  const end = moment.toFormat('yyyy-MM-dd HH:59:59');

  const [deviceId, setDeviceId] = useState('');
  const [driverNo, setDriverNo] = useState('');
  const [companyCode, setCompanyCode] = useState('');
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const [logs, setLogs] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [includeGps, setIncludeGps] = useState(true);
  const [includeMessaging, setIncludeMessaging] = useState(true);
  const [includeJobEvents, setIncludeJobEvents] = useState(true);
  const [includeLogin, setIncludeLogin] = useState(true);
  const [includeOther, setIncludeOther] = useState(true);
  const [gpsTrail, setGpsTrail] = useState([]);
  const [apiKey, setApiKey] = useState(''); // Google maps API key
  const [searchArgs, setSearchArgs] = useState({});

  const search = () => {
    //{"start":"2024-04-16 01:00:00","end":"2024-04-16 14:59:59","deviceId":"74688"}
    //{"start":"2024-04-16 01:00:00","end":"2024-04-16 14:59:59","deviceId":"","driverNo":"3333","company":"PUMA"}
    doSearch(startDate, endDate, deviceId);
  };

  const doSearch = (start, end, dId) => {
    setRequestInProgress(true);
    searchNext(start, end, dId, true);
  };

  const searchByDeviceId = (dId) => {
    setDeviceId(dId);
    setDriverNo('');
    setCompanyCode('');
    doSearch(startDate, endDate, dId);
  };

  useEffect(() => {
    if (
      'start' in searchArgs &&
      'end' in searchArgs &&
      'dId' in searchArgs &&
      'first' in searchArgs
    ){
      const { start, end, dId, first } = searchArgs;
      searchNext(start, end, dId, first);
    }
  }, [searchArgs]);

  const searchNext = (start, end, dId, first) => {

    var url = "ajax/getDriverLogs.php";

    const args = { start, end, deviceId: dId };
    if (driverNo.length > 0) args.driverNo = driverNo;
    if (companyCode.length > 0) args.company = companyCode;
    
    const success = (result) => searchCallback(dId, result, first);
    
    AjaxUtil.fetchPost({ url, args, success });

  }

  const searchCallback = (dId, result, first) => {
    
    if (result.error){
      console.error(result.error);
      setRequestInProgress(false);
      return;
    }else if (result.data.length > 0){
      //console.log("Logs length: "+logs.length);
      const newLogs = result.data.map(r => {
        if (!first){
          r.index += logs.length;
        }
        return r;
      });
      if (first){
        //console.log("Setting logs");
        setLogs(newLogs);
      }else{
        //console.log("Appending logs");
        setLogs([
          ...logs,
          ...newLogs
        ]);
      }
    }

    if ('finish' in result && result.finish === true){
      setRequestInProgress(false);
    }else{
      setSearchArgs({
        start:result.start,
        end,
        dId,
        first:false
      })
    }
    

  }

  return {
    deviceId, setDeviceId,
    driverNo, setDriverNo,
    companyCode, setCompanyCode,
    startDate, setStartDate,
    endDate, setEndDate,
    logs, setLogs,
    requestInProgress, setRequestInProgress,
    includeGps, setIncludeGps,
    includeMessaging, setIncludeMessaging,
    includeJobEvents, setIncludeJobEvents,
    includeLogin, setIncludeLogin,
    includeOther, setIncludeOther,
    gpsTrail, setGpsTrail,
    apiKey, setApiKey,
    search,
    searchByDeviceId
  };

}
