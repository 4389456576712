import { useState } from 'react';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.css';
import './common/css/style.css';
import './css/style.css';
import './css/App.css';
import DriverListViewModel from './js/viewmodels/DriverListViewModel.js';
import CompanyListViewModel from './js/viewmodels/CompanyListViewModel.js';
import OperatorLogsListViewModel from './js/viewmodels/OperatorLogsListViewModel.js';
import DriverLogsListViewModel from './js/viewmodels/DriverLogsListViewModel.js';
import NavBar from './common/components/NavBar.tsx';
import CompanyList from './components/CompanyList.jsx';
import DriverList from './components/DriverList.jsx';
import OperatorLogsList from './components/OperatorLogsList.jsx';
import DriverLogsList from './components/DriverLogsList.jsx';
import LoadingScreen from './components/LoadingScreen.jsx';

function App() {

  const [tab, setTab] = useState('');
  const modelDriverList = DriverListViewModel();
  const modelCompanyList = CompanyListViewModel();
  const modelOperatorLogs = OperatorLogsListViewModel();
  const modelDriverLogs = DriverLogsListViewModel();

  function goToDriverTab(c){
    modelDriverList.searchByCompany(c);
    setTab('drivers');
  }

  function goToDriverLogsTab(deviceId){
    modelDriverLogs.searchByDeviceId(deviceId);
    setTab('driverlogs');
  }

  function goToOperatorLogsTab(d){
    modelOperatorLogs.searchByDriver(d);
    setTab('operatorlogs');
  }

  function goToCompaniesTab(){
    setTab('companies');
  }
  

  const content =
    (tab === 'operatorlogs') ?
      (<OperatorLogsList model={modelOperatorLogs}/>)
    : (tab === 'drivers') ?
      (<DriverList
        goToDriverLogsTab={goToDriverLogsTab}
        goToOperatorLogsTab={goToOperatorLogsTab}
        model={modelDriverList}
        />)
    : (tab === 'driverlogs') ?
      (<DriverLogsList model={modelDriverLogs}/>)
    : (tab === 'companies') ?
      (<CompanyList
        goToDriverTab={goToDriverTab}
        model={modelCompanyList}
        />)
    :
      (<LoadingScreen
        setCompanies={modelCompanyList.setCompanies}
        setConfigs={modelDriverList.setConfigs}
        setApiKey={modelDriverLogs.setApiKey}
        finished={goToCompaniesTab}
        />);

  const navbarTabs = [
    {tab:'companies', text:'Company Search'},
    {tab:'drivers', text:'Driver Search'},
    {tab:'operatorlogs', text:'Operator Logs'},
    {tab:'driverlogs', text:'Driver Logs'}
  ];

  return (
    <div className="App">
      <NavBar title="Devices" tabs={navbarTabs} selectedTab={tab} setTab={setTab}/>
      <br/>
      
      <div className="container">
        {content}
      </div>

    </div>
  );
}



export default App;
