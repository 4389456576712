import { useState } from 'react';
import AjaxUtil from '../../common/js/AjaxUtil.ts';

export default function OperatorLogsListViewModel(){

  const [uuid, setUuid] = useState('');
  const [logs, setLogs] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const search = () => {
    //{"d_device_id":"8C0BCC427383D1A05AD6C96D26C8FA43"}
    doSearch({ d_device_id: uuid });
  };

  const doSearch = (args) => {
    var url = "ajax/getOperatorLogs.php";
    AjaxUtil.fetchPost({ url, args, success:setLogs, setRequestInProgress });
  };

  const searchByDriver = (d) => {
    setUuid(d.d_device_id);
    doSearch({ d_device_id: d.d_device_id });
  };

  return {
  	uuid, setUuid,
  	logs, setLogs,
    requestInProgress, setRequestInProgress,
    search,
    searchByDriver
  };

}
