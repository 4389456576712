export default function DriverConfig({configs, value, setValue}){

  const handleChange = (e) => setValue(e.target.value);

  const configNodes = configs.map(c => DriverConfigOption(c.file));

  return (
    <select className="form-control" value={value} onChange={handleChange}>
      { configNodes }
    </select>
  );

}

function DriverConfigOption(name){
  return (
    <option key={name} value={name}>{ name }</option>
  );
}