export default function DriverListActionModal({
	driver,
	setDriver,
	goToDriverLogsTab,
	goToOperatorLogsTab,
	setModifyDriver,
}){

	if (driver === null){
		return (<></>)
	}

	function dismiss(){
		setDriver(null);
	}

	function getDriverId(d) {
		if (d.d_type === 'HubMobile' || d.d_type === 'HubDespatch iOS' || d.d_type === 'HubWarehouse iOS') {
			return 'IOS' + d.d_id.toString().padStart(6, '0');
		} else if (d.d_type === 'HubMobileAndroid' || d.d_type === 'HubDespatch Android' || d.d_type === 'HubWarehouse Android') {
			return 'AND' + d.d_id.toString().padStart(6, '0');
		} else {
			return d.d_id.toString();
		}
	}
	
	function showUpdateDriverModal(){
		setModifyDriver(driver);
		dismiss();
	}

	function showOperatorLogsTab(){
		goToOperatorLogsTab(driver);
		dismiss();
	}

	function showDriverLogsTab(){
		const driverId = getDriverId(driver);
		goToDriverLogsTab(driverId);
		dismiss();
	}

	function copyIDToClipboard(){
		var driverId = getDriverId(driver);
		navigator.clipboard.writeText(driverId);
	}

	return (
		<div className="modal show" tabIndex="-1">
		  <div className="modal-dialog">
		    <div className="modal-content">
		      <div className="modal-header">
		        <h5 className="modal-title">Driver #{ driver.d_driver }</h5>
		        <button type="button" className="btn-close" onClick={dismiss}></button>
		      </div>
		      <div className="modal-body">
		      	<div className="list-group">
		      		<ButtonAction text="View Operator Logs" click={showOperatorLogsTab}/>
		      		<ButtonAction text="View Driver Logs" click={showDriverLogsTab}/>
		      		<ButtonAction text="Update Driver" click={showUpdateDriverModal}/>
		      		<ButtonAction text="Copy ID to Clipboard" click={copyIDToClipboard}/>
		      	</div>
		      </div>
		      <div className="modal-footer">
		        <button type="button" className="btn btn-danger" onClick={dismiss}>Close</button>
		      </div>
		    </div>
		  </div>
		</div>

	);

}

function ButtonAction({text, click}){
	return (
		<a
		key={text}
		className="list-group-item list-group-item-action"
		href="#!"
		onClick={click}>
			{text}
		</a>
	);
}