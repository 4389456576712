import { useState } from 'react';
import AjaxUtil from '../../common/js/AjaxUtil.ts';

export default function LoadingScreenViewModel(){

	const [message, setMessage] = useState("Loading...");

	const loadData = (setCompanies, setConfigs, finished) => {
		Promise.all([
			fetch("ajax/getCompanyList.php").then(res => res.json()),
			fetch("ajax/getConfigNames.php").then(res => res.json()),
		]).then(results => {
    		
    		setCompanies(results[0]);

    		setConfigs(results[1]);
			
			finished();

		});
	};

	const loadSession = (setCompanies, setConfigs, setApiKey, finished) => {
		var url = "ajax/getSession.php";
		var success = (result) => {
			if (result.success){
				setApiKey(result.apiKey);
				loadData(setCompanies, setConfigs, finished);
			}else if (result.loginUrl){
				window.location.href = result.loginUrl;
			}else{
				setMessage("Failed to retrieve session");
			}
		};
		AjaxUtil.fetchPost({ url, success });
	};

	return {
		message, setMessage,
		loadSession
	};

}
