import InputField from '../common/components/InputField.tsx';
import Checkbox from '../common/components/Checkbox.tsx';
import DriverMap from './DriverMap.jsx';
import AlertModal from './AlertModal.jsx';
import AlertViewModel from '../js/viewmodels/AlertViewModel.js';
import { DateTime } from "luxon";

export default function DriverLogsList({
    model
}) {

    const modelAlert = AlertViewModel();

    function submit(){

      var format = "yyyy-mm-dd HH:mm:ss";
      var start = DateTime.fromFormat(model.startDate, format);
      var end = DateTime.fromFormat(model.endDate, format);

      var hasDeviceId = model.deviceId.length > 0;
      var hasDriverNo = model.driverNo.length > 0;
      var hasCompany = model.companyCode.length > 0;

      if (hasDeviceId && (hasDriverNo || hasCompany)){
        modelAlert.show('Enter device id OR driver number + company', 'Error');
        return;
      }

      if (!hasDeviceId && !(hasDriverNo && hasCompany)){
        modelAlert.show('Enter device id OR driver number + company', 'Error');
        return;
      }

      if (hasDriverNo !== hasCompany){
        modelAlert.show('Enter driver number AND company, not just one', 'Error');
        return;
      }

      if (!start.isValid || !end.isValid || start > end){
        modelAlert.show('Invalid date/time range', 'Error');
        return;
      }

      model.search();
    }

    function cancel(){
      //TODO ajax request
      model.setRequestInProgress(false);
    }

    function showMap(){
      var gpsTrail = model.logs.filter(r => r.type === 'gps');
      model.setGpsTrail(gpsTrail);
    }

    function hideMap(){
      model.setGpsTrail([]);
    }

    const submitButton = model.requestInProgress ?
      (<button className="btn btn-danger" type="button" onClick={cancel}>Cancel</button>) :
      (<button className="btn btn-success" type="button" onClick={submit}>Submit</button>);

    const tableRows = model.logs.filter(l => {
      if (l.type === "gps") return model.includeGps;
      if (l.type === "message") return model.includeMessaging;
      if (l.type === "job") return model.includeJobEvents;
      if (l.type === "login") return model.includeLogin;
      if (l.type === "other") return model.includeOther;
      return true;
    }).map(TableRow);

    return (
        <>
          <div className="row">
            <div className="col l2 s6 input-field">
              <InputField
                label="Device ID"
                value={model.deviceId}
                setValue={model.setDeviceId}
                onEnter={submit}
                autoFocus={true}
                />
            </div>
            <div className="col l2 s6 input-field">
              <InputField
                label="Driver Number"
                value={model.driverNo}
                setValue={model.setDriverNo}
                onEnter={submit}
                />
            </div>
            <div className="col l2 s6 input-field">
              <InputField
                label="Company Code"
                value={model.companyCode}
                setValue={model.setCompanyCode}
                onEnter={submit}
                />
            </div>
          </div>
          <div className="row">
    
            <div className="col l2 s6 input-field">
              <InputField
                label="Start (YYYY-MM-DD HH:mm:ss)"
                value={model.startDate}
                setValue={model.setStartDate}
                onEnter={submit}
                />
            </div>
            <div className="col l2 s6 input-field">
              <InputField
                label="End (YYYY-MM-DD HH:mm:ss)"
                value={model.endDate}
                setValue={model.setEndDate}
                onEnter={submit}
                />
            </div>
            <div className="col l2 s6 input-field">
              <div className="mb-3">
                <br/>
                {submitButton}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-auto">
              <button className="btn btn-primary" type="button" onClick={showMap}>
                Show Map
              </button>
            </div>
          </div>

          <br/>

          <div className="row">
            <div className="col-auto">
              <Checkbox
                id="gps"
                label="GPS"
                value={model.includeGps}
                setValue={model.setIncludeGps}
                />
            </div>
            <div className="col-auto">
              <Checkbox
                id="messaging"
                label="Messaging"
                value={model.includeMessaging}
                setValue={model.setIncludeMessaging}
                />
            </div>
            <div className="col-auto">
              <Checkbox
                id="jobevents"
                label="Job Events"
                value={model.includeJobEvents}
                setValue={model.setIncludeJobEvents}
                />
            </div>
            <div className="col-auto">
              <Checkbox
                id="login"
                label="Login"
                value={model.includeLogin}
                setValue={model.setIncludeLogin}
                />
            </div>
            <div className="col-auto">
              <Checkbox
                id="other"
                label="Other"
                value={model.includeOther}
                setValue={model.setIncludeOther}
                />
            </div>
          </div>

          <br/>

          <table className="table">
            <thead>
              <tr>
                <th>Timestamp</th>
                <th>Origin</th>
                <th>Event</th>
              </tr>
            </thead>
            <tbody>
              {tableRows}
            </tbody>
          </table>

          <DriverMap
            coords={model.gpsTrail}
            close={hideMap}
            apiKey={model.apiKey}
            />
          <AlertModal model={modelAlert}/>
        </>
      )

}

function TableRow(l){
  const lines = l.text.split('\n').map(TableRowText);
	return (
		<tr key={l.index}>
			<td>{ l.stamp }</td>
			<td>{ l.origin }</td>
			<td>{ lines }</td>
		</tr>
  );
}

function TableRowText(text, index){
  return (<span key={index}>{ text }<br/></span>);
}