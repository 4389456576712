import {APIProvider, Map, Marker} from '@vis.gl/react-google-maps';

export default function DriverMap({
    coords,
    close,
    apiKey
}) {

    if (coords.length === 0){
        return (<></>);
    }

    const position = {lat: coords[0].coord.lat, lng: coords[0].coord.lon};
    const markers = coords.map(DriverMapMarker);

    return (
        <div className="fullscreen-map">
            <div className="header">
                <button className="btn btn-danger float-right" onClick={close}>Close</button>
            </div>
            <div className="body">
                <APIProvider apiKey={apiKey}>
                    <Map defaultCenter={position} defaultZoom={14}>
                        { markers }
                    </Map>
                </APIProvider>
            </div>
        </div>
    );

}

function DriverMapMarker(c){
    const position = {lat: c.coord.lat, lng: c.coord.lon};
    return (<Marker position={position} />)
}