import { useState } from 'react';
import AjaxUtil from '../../common/js/AjaxUtil.ts';

export default function DriverModifyViewModel(){

	const [show, setShow] = useState(false);
	const [driver, setDriver] = useState(null);
	const [driverNo, setDriverNo] = useState('');
	const [driverName, setDriverName] = useState('');
	const [driverStatus, setDriverStatus] = useState('');
	const [driverConfig, setDriverConfig] = useState('');
	const [driverType, setDriverType] = useState('');
	const [configs, setConfigs] = useState([]);
	const [requestInProgress, setRequestInProgress] = useState(false);

	const showPrompt = (model) => {
		setDriver(model.modalDriver);
		setDriverNo(model.modalDriver.d_driver);
		setDriverName(model.modalDriver.d_driverName);
		setDriverStatus(model.modalDriver.d_status);
		setDriverConfig(model.modalDriver.d_configfile);
		setDriverType(model.modalDriver.d_type);
		setConfigs(model.configs);
		setShow(true);
	};

	const submit = (toast, save) => {
		const url = "ajax/updateDriver.php";
		const args = {
			d_id: driver.d_id,
			d_driver: driverNo,
			d_driverName: driverName,
			d_status: driverStatus,
			d_configfile: driverConfig
		};
		const success = (json) => {
			if (json.success){
				toast('Driver updated successfully');
				save();
				setShow(false);
			}else{
				toast('Driver update failed');
			}
		};
    	AjaxUtil.fetchPost({ url, args, success, setRequestInProgress });
	}

	return {
		show, setShow,
		driverNo, setDriverNo,
		driverName, setDriverName,
		driverStatus, setDriverStatus,
		driverConfig, setDriverConfig,
		driverType, setDriverType,
		driver, setDriver,
		configs, setConfigs,
		requestInProgress, setRequestInProgress,
		showPrompt,
		submit
	};

}
