import { useState } from 'react';
import { DateTime } from "luxon";
import AjaxUtil from '../../common/js/AjaxUtil.ts';

export default function DriverListViewModel(){

  const moment = DateTime.now();
  const start = moment.minus({ months: 1 }).toFormat('yyyy-MM-dd');
  const end = moment.toFormat('yyyy-MM-dd');

  const [companyId, setCompanyId] = useState('');
  const [deviceType, setDeviceType] = useState('');
  const [driverNo, setDriverNo] = useState('');
  const [driverStatus, setDriverStatus] = useState('Y');
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const [breakdownType, setBreakdownType] = useState('');
  const [modalDriver, setModalDriver] = useState(null);
  const [drivers, setDrivers] = useState([]);
  const [configs, setConfigs] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const search = () => {
    //{"d_company":112,"d_driver":"","d_status":"Y","d_type":"","d_start":"2024-03-16T00:00:00","d_end":"2024-04-16T23:59:59"}
    doSearch({
      d_company: companyId,
      d_driver: driverNo,
      d_status: driverStatus,
      d_type: deviceType,
      d_start: startDate,
      d_end: endDate
    });
  };

  const doSearch = (args) => {
    var url = "ajax/getDriverList.php";
    AjaxUtil.fetchPost({ url, args, success: setDrivers, setRequestInProgress });
  };

  const toggleBreakdown = (type) => {
    var newType = breakdownType === type ? '' : type;
    setBreakdownType(newType);
  };

  const searchByCompany = (c) => {
    
    setCompanyId(c.c_id);
    setDriverNo('');
    setDriverStatus('Y');
    setDeviceType('');

    // Insert args directly instead of calling search()
    // because there's a delay between using the set function
    // of a useState variable.
    doSearch({
      d_company: c.c_id,
      d_driver: '',
      d_status: 'Y',
      d_type: '',
      d_start: startDate,
      d_end: endDate
    });

  };

  return {
    companyId, setCompanyId,
    deviceType, setDeviceType,
    driverNo, setDriverNo,
    driverStatus, setDriverStatus,
    startDate, setStartDate,
    endDate, setEndDate,
    breakdownType, setBreakdownType,
    modalDriver, setModalDriver,
    drivers, setDrivers,
    configs, setConfigs,
    requestInProgress, setRequestInProgress,
    search,
    toggleBreakdown,
    searchByCompany
  };

}
