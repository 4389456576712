import React from "react";
import logo from '../img/logo-icon-white.png';

/**
 * The NavBar component provides a consistent navigation bar component
 * for use across multiple Hub websites.
 * It expects the parent project to implement Bootstrap (CSS and JS)
 * and to include the css/style.css file from this repository.
 * 
 * @param title Text to display beside the Hub icon in the navbar
 * 
 * @param tabs Array of {tab:'Key', text:'Name'} fields, where the key is
 * used to define which page the user is navigating to, and the text is
 * what to display in the actual tab.
 * eg. {tab:'companies', text:'Company List'}
 * 
 * @param selectedTab The currently selected tab.
 * This should be the first value (getter) of a useState text field which
 * is managed by the parent component.
 * ie. const [selectedTab, setTab] = useState('');
 * 
 * @param setTab Callback to set the newly-selected tab.
 * This should be the second value (setter) of a useState text field which
 * is managed by the parent component.
*/
export default function NavBar({
  title,
  tabs,
  selectedTab,
  setTab
}: INavBar) {

  const navbarTabs = tabs.map(t => NavBarTab({t, setTab, selectedTab}))
  return (
    <nav className="navbar navbar-expand-md">
      <div className="container-fluid">
        <div className="navbar-brand">
          <img src={logo} alt="Logo" width="24" height="24" className="d-inline-block align-text-top"/>
          &nbsp;{title}
        </div>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            {navbarTabs}
          </ul>
        </div>
      </div>
    </nav>
  );
}

function NavBarTab({
  t,
  setTab,
  selectedTab
}: INavBarTab){

  const click = () => setTab(t.tab);
  const aClass = selectedTab === t.tab ? "nav-link active" : "nav-link";

  return (
    <li key={t.tab} className="nav-item">
      <a className={aClass} onClick={click} href="#!">
        {t.text}
      </a>
    </li>
  );
}

interface INavBar{
  title: string;
  tabs: Array<ITab>;
  selectedTab: string;
  setTab: (tab: string) => void;
}

interface INavBarTab{
  t: ITab;
  setTab: (tab: string) => void;
  selectedTab: string;
}

export interface ITab{
  tab: string;
  text: string;
}