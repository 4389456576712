import { useEffect } from 'react';
import LoadingScreenViewModel from '../js/viewmodels/LoadingScreenViewModel.js';

export default function LoadingScreen({
	setCompanies,
	setConfigs,
	setApiKey,
	finished
}) {

	const model = LoadingScreenViewModel();

	useEffect(() => {
		model.loadSession(setCompanies, setConfigs, setApiKey, finished);
	}, []);

	return (
		<h1>{ model.message }</h1>
	)
}