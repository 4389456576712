export default function DriverStatus({value, setValue}){

  const handleChange = (e) => setValue(e.target.value);

  return (
    <select className="form-control" value={value} onChange={handleChange}>
      <option value="">-- Driver Status --</option>
      <option value="Y">Confirmed</option>
      <option value="X">Deleted</option>
      <option value="N">Pending</option>
    </select>
  );

}