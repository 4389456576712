import InputField from '../common/components/InputField.tsx';

export default function CompanyList({
	goToDriverTab,
	model
}) {

	const companiesFiltered = model.companies.filter(c => {
		return c.c_name.toLowerCase().includes(model.criteria.toLowerCase()) ||
		c.c_mqserver_name.toLowerCase().includes(model.criteria.toLowerCase());
	});
	const tableRows = companiesFiltered.map(c => tableRow(c, goToDriverTab));

	// If the user pressed ENTER, look for a match
	const submit = () => {

		var criteria = model.criteria.toLowerCase();
		var mqMatch = companiesFiltered.find(c => criteria === c.c_mqserver_name.toLowerCase());
		var nameMatch = companiesFiltered.find(c => criteria === c.c_name.toLowerCase());

		if (mqMatch){
			goToDriverTab(mqMatch);
		}else if (nameMatch){
			goToDriverTab(nameMatch);
		}else if (companiesFiltered.length > 0){
			goToDriverTab(companiesFiltered[0]);
		}

	};

	return (
		<>
			<div className="row">
				<div className="col s12 input-field">
					<InputField
						name="Filter"
						value={model.criteria}
						setValue={model.setCriteria}
						autoFocus={true}
						onEnter={submit}
						/>
				</div>
			</div>
			<table className="table table-hover">
				<thead>
					<tr>
						<th className="hide-on-med-and-down">ID</th>
						<th>Code</th>
						<th>Name</th>
						<th className="hide-on-small-and-down">MQ Port</th>
						<th className="hide-on-small-and-down">MQ Host</th>
						<th className="hide-on-small-and-down">MQ Server</th>
					</tr>
				</thead>
				<tbody>
					{tableRows}
				</tbody>
			</table>
		</>
	)
}

function tableRow(c, goToDriverTab){

	function click(){
		goToDriverTab(c)
	}
	
	return (
		<tr key={c.c_id} onClick={click}>
			<td className="hide-on-med-and-down">{ c.c_id }</td>
			<td>{ c.c_code }</td>
			<td>{ c.c_name }</td>
			<td className="hide-on-small-and-down">{ c.c_mqserver_port }</td>
			<td className="hide-on-small-and-down">{ c.c_mqserver_host }</td>
			<td className="hide-on-small-and-down">{ c.c_mqserver_name }</td>
		</tr>
	);
}