import { useState } from 'react';

export default function CompanyListViewModel(){

  const [criteria, setCriteria] = useState('');
  const [companies, setCompanies] = useState([]);

  return {
  	criteria,
  	setCriteria,
  	companies,
  	setCompanies
  };

}
