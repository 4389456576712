import DriverStatus from './DriverStatus.jsx';
import DriverConfig from './DriverConfig.jsx';
import InputField from '../common/components/InputField.tsx';
import SubmitButton from '../common/components/SubmitButton.tsx';

export default function DriverModifyModal({
	model,
	save,
	toast
}){

	if (!model.show){
		return (<></>)
	}

	const dismiss = () => model.setShow(false);
	const submit = () => model.submit(toast, save);

	const filteredConfigs =
		model.driverType.startsWith('HubMobile') ? model.configs['hubmobile'] :
		model.driverType.startsWith('HubDespatch') ? model.configs['hubdespatch'] :
		model.driverType.startsWith('HubWarehouse') ? model.configs['hubwarehouse'] :
		[];

	return (
		<div className="modal show" tabIndex="-1">
		  <div className="modal-dialog">
		    <div className="modal-content">
		      <div className="modal-header">
			  	<h5 className="modal-title">Driver #{model.driver.d_driver}</h5>
		        <button type="button" className="btn-close" onClick={dismiss}></button>
		      </div>
		      <div className="modal-body">
		      	<div className="row">
			        <div className="col s12 input-field">
			          <InputField
			            label="Driver Number"
			            value={model.driverNo}
			            setValue={model.setDriverNo}
			            />
			        </div>
				</div>
				<div className="row">
			        <div className="col s12 input-field">
			          <InputField
			            label="Driver Name"
			            value={model.driverName}
			            setValue={model.setDriverName}
			            />
			        </div>
				</div>
				<div className="row">
			        <div className="col l2 s6 input-field">
			          <div className="mb-3">
					  <label className="small">Authentication Status</label>
			            <br/>
			            <DriverStatus
			              value={model.driverStatus}
			              setValue={model.setDriverStatus}
			              />
			          </div>
			        </div>
				</div>
				<div className="row">
			        <div className="col l2 s6 input-field">
			          <div className="mb-3">
					  	<label className="small">Configuration File</label>
			            <br/>
			            <DriverConfig
			              configs={filteredConfigs}
			              value={model.driverConfig}
			              setValue={model.setDriverConfig}
			              />
			          </div>
			        </div>
		      	</div>
		      </div>
		      <div className="modal-footer">
				<SubmitButton
					onClick={submit}
					text="Save"
					requestInProgress={model.requestInProgress}
					loadingText="Saving..."
					/>
		      </div>
		    </div>
		  </div>
		</div>

	);

}