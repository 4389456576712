import { useState } from 'react';

export default function DriverList({
  drivers, click
}) {

  const intSortKeys = ['d_driver', 'd_company', 'd_id'];
  const stringSortKeys = ['d_driverName', 'd_status', 'd_configfile', 'd_deviceName', 'd_type'];
  const timestampSortKeys = ['d_timestamp'];
  const versionSortKeys = ['d_version', 'd_osVersion'];

  const [sortBy, setSortBy] = useState('d_timestamp');
  var sortAsc = false;
  const driverList = drivers.sort((a, b) => {
    
    if (intSortKeys.includes(sortBy)){
      return sortByNumber(a, b);
    } else if (stringSortKeys.includes(sortBy)) {
      return sortByString(a, b);
    } else if (timestampSortKeys.includes(sortBy)) { //TODO: not working?
      return sortByTimestamp(a, b);
    } else if (versionSortKeys.includes(sortBy)) {
      return sortByVersion(a, b);
    } else if (sortBy.length === 0){
      // No sorting defined - do nothing
    } else {
      console.log(`Unknown column: ${sortBy}`);
    }

    return 0;

  });

  const driverRows = driverList.map(d => DriverRow(d, click));

  function sortDrivers(by) {

    if (sortBy === by) {
      sortAsc = !sortAsc;
    } else {
      sortAsc = false;
    }

    setSortBy(by);

  }

  function sortByVersion(a, b) {
    a = a[sortBy];
    b = b[sortBy];
    if (a === b) return 0;

    var v1 = a.replace(/[^0-9.]/g, '').split('.');
    var v2 = b.replace(/[^0-9.]/g, '').split('.');

    var maxLength = v1.length > v2.length ? v1.length : v2.length;

    var aBigger = true;
    for (var i = 0; i < maxLength; i++) {
      var a2 = i < v1.length ? parseInt(v1[i]) : 0;
      var b2 = i < v2.length ? parseInt(v2[i]) : 0;

      if (b2 > a2) {
        aBigger = false;
        break;
      } else if (b2 === a2) {
        continue;
      } else {
        break;
      }
    }

    // hijack results
    if (a.length === 0) {
      aBigger = false;
    } else if (b.length === 0) {
      aBigger = true;
    }

    if (sortAsc)
      return aBigger ? 1 : -1;
    else
      return aBigger ? -1 : 1;
  }

  function sortByTimestamp(a, b) {
    var d1 = new Date(a[sortBy]);
    var d2 = new Date(b[sortBy]);
    if (sortAsc)
      return d1 - d2;
    else
      return d2 - d1;
  }

  function sortByString(a, b) {
    if (a[sortBy] === null) a[sortBy] = '';
    if (b[sortBy] === null) b[sortBy] = '';
    if (sortAsc)
      return a[sortBy].localeCompare(b[sortBy]);
    else
      return b[sortBy].localeCompare(a[sortBy]);
  }

  function sortByNumber(a, b) {
    if (a[sortBy] === null) a[sortBy] = 0;
    if (b[sortBy] === null) b[sortBy] = 0;
    if (sortAsc)
      return a[sortBy] - b[sortBy];
    else
      return b[sortBy] - a[sortBy];
  }

  const sortDriversById = () => sortDrivers('d_id');
  const sortDriversByNumber = () => sortDrivers('d_driver');
  const sortDriversByName = () => sortDrivers('d_driverName');
  const sortDriversByStatus = () => sortDrivers('d_status');
  const sortDriversByConfig = () => sortDrivers('d_configfile');
  const sortDriversByDevice = () => sortDrivers('d_deviceName');
  const sortDriversByType = () => sortDrivers('d_type');
  const sortDriversByCompany = () => sortDrivers('d_company');
  const sortDriversByTimestamp = () => sortDrivers('d_timestamp');
  const sortDriversByAppVersion = () => sortDrivers('d_version');
  const sortDriversByOsVersion = () => sortDrivers('d_osVersion');

  return (
  	<table className="table table-hover">
      <thead>
        <tr>
          <th onClick={sortDriversById} className="thSort">
            ID
          </th>
          <th onClick={sortDriversByNumber} className="thSort">
            Drv
          </th>
          <th onClick={sortDriversByName} className="thSort hide-on-med-and-down">
            Name
          </th>
          <th onClick={sortDriversByStatus} className="thSort">
            Status
          </th>
          <th onClick={sortDriversByConfig} className="thSort hide-on-small-and-down">
            Config
          </th>
          <th onClick={sortDriversByDevice} className="thSort hide-on-small-and-down">
            Device
          </th>
          <th onClick={sortDriversByType} className="thSort hide-on-med-and-down">
            Type
          </th>
          <th onClick={sortDriversByCompany} className="thSort hide-on-med-and-down">
            Company ID
          </th>
          <th onClick={sortDriversByTimestamp} className="thSort hide-on-small-and-down">
            Timestamp
          </th>
          <th onClick={sortDriversByAppVersion} className="thSort hide-on-small-and-down">
            App
          </th>
          <th onClick={sortDriversByOsVersion} className="thSort hide-on-small-and-down">
            OS
          </th>
        </tr>
      </thead>
      <tbody>
        {driverRows}
      </tbody>
    </table>
  );

}

function DriverRow(d, prompt){

	function getDriverId(d) {
		if (d.d_type === 'HubMobile' || d.d_type === 'HubDespatch iOS' || d.d_type === 'HubWarehouse iOS') {
			return 'IOS' + d.d_id.toString().padStart(6, '0');
		} else if (d.d_type === 'HubMobileAndroid' || d.d_type === 'HubDespatch Android' || d.d_type === 'HubWarehouse Android') {
			return 'AND' + d.d_id.toString().padStart(6, '0');
		} else {
			return d.d_id.toString();
		}
	}

	function rowClick(){
		prompt(d);
	}

    return (
        <tr key={d.d_id} onClick={rowClick}>
          <td>{ getDriverId(d) }</td>
          <td>{ d.d_driver }</td>
          <td className="hide-on-med-and-down">{ d.d_driverName }</td>
          <td>{ d.d_status }</td>
          <td className="hide-on-small-and-down">{ d.d_configfile }</td>
          <td className="hide-on-small-and-down">{ d.d_deviceName }</td>
          <td className="hide-on-med-and-down">{ d.d_type }</td>
          <td className="hide-on-med-and-down">{ d.d_company }</td>
          <td className="hide-on-small-and-down">{ d.d_timestamp }</td>
          <td className="hide-on-small-and-down">{ d.d_version }</td>
          <td className="hide-on-small-and-down">{ d.d_osVersion }</td>
        </tr>
    )
}
