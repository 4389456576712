import InputField from '../common/components/InputField.tsx';
import SubmitButton from '../common/components/SubmitButton.tsx';

export default function OperatorLogsList({
  model
}) {

    const tableRows = model.logs.map(TableRow);

    return (
		<>
			<div className="row">
			   <div className="col l2 s6 input-field">
			    <InputField
			    	id="uuid"
			    	name="UUID"
			    	value={model.uuid}
			    	setValue={model.setUuid}
					autoFocus={true}
			    	/>
			 	</div>
		        <div className="col l2 s6 input-field">
		          <div className="mb-3">
					<SubmitButton onClick={model.search} requestInProgress={model.requestInProgress}/>
		          </div>
		        </div>
			</div>
			<table className="table table-hover">
				<thead>
					<tr>
						<th>Operator</th>
						<th>Driver #</th>
						<th className="hide-on-small-and-down">Driver Name</th>
						<th>Status</th>
						<th>Config</th>
						<th>Timestamp</th>
					</tr>
				</thead>
				<tbody>
					{tableRows}
				</tbody>
			</table>
		</>
	);

}

function TableRow(l){
	return (
		<tr key={l.ol_id}>
			<td>{ l.ol_operator }</td>
			<td>{ l.ol_driver }</td>
			<td className="hide-on-small-and-down">{ l.ol_driver_name }</td>
			<td>{ l.ol_status }</td>
			<td>{ l.ol_configfile }</td>
			<td>{ l.ol_timestamp }</td>
		</tr>
  );
}