import InputField from '../common/components/InputField.tsx';
import DriverTable from './DriverTable.jsx';
import DriverListActionModal from './DriverListActionModal.jsx';
import DriverStatus from './DriverStatus.jsx';
import DriverModifyModal from './DriverModifyModal.jsx';
import AlertModal from './AlertModal.jsx';
import DriverModifyViewModel from '../js/viewmodels/DriverModifyViewModel.js';
import AlertViewModel from '../js/viewmodels/AlertViewModel.js';
import SubmitButton from '../common/components/SubmitButton.tsx';

export default function DriverList({
  goToDriverLogsTab,
  goToOperatorLogsTab,
  model
}) {

  const modelDriverModify = DriverModifyViewModel();
  const modelAlert = AlertViewModel();

  const toggleBreakdownVersion = () => model.toggleBreakdown('version');
  const toggleBreakdownType = () => model.toggleBreakdown('type');
  const toggleBreakdownConfig = () => model.toggleBreakdown('config');
  const showModifyPrompt = () => modelDriverModify.showPrompt(model);

  function exportDriverList(){

    if (model.companyId.length === 0) {
      modelAlert.show('No company ID specified');
      return;
    }

    var url = 'ajax/downloadDriverList.php?companyId=' + model.companyId;
    if (model.driverNo.length > 0) url += '&driverNo=' + model.driverNo;
    if (model.driverStatus.length > 0) url += '&driverStatus=' + model.driverStatus;
    if (model.deviceType.length > 0) url += '&driverType=' + model.deviceType;

    window.open(url, '_blank');
  }

  return (
    <>
      <div className="row">
        <div className="col l2 s6 input-field">
          <InputField
            label="Company ID"
            value={model.companyId}
            setValue={model.setCompanyId}
            onEnter={model.search}
            autoFocus={true}
            />
        </div>
        <div className="col l2 s6 input-field">
          <InputField
            label="Driver Number"
            value={model.driverNo}
            setValue={model.setDriverNo}
            onEnter={model.search}
            />
        </div>
        <div className="col l2 s6 input-field">
          <div className="mb-3">
            <br/>
            <DriverStatus
              value={model.driverStatus}
              setValue={model.setDriverStatus}
              />
          </div>
        </div>
        <div className="col l2 s6 input-field">
          <div className="mb-3">
            <br/>
            <DeviceType
              value={model.deviceType}
              setValue={model.setDeviceType}
              />
          </div>
        </div>
        <div className="col l2 s6 input-field">
          <div className="mb-3">
            <br/>
            <SubmitButton onClick={model.search} requestInProgress={model.requestInProgress}/>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col l2 s6 input-field">
          <InputField
            label="Start"
            type="date"
            value={model.startDate}
            setValue={model.setStartDate}
            onEnter={model.search}
            />
        </div>
        <div className="col l2 s6 input-field">
          <InputField
            label="End"
            type="date"
            value={model.endDate}
            setValue={model.setEndDate}
            onEnter={model.search}
            />
        </div>
      </div>

      <div className="row">
        <div className="col-auto">
          <button className="btn btn-primary" type="button" onClick={toggleBreakdownVersion}>
            Version Breakdown
          </button>
        </div>
        <div className="col-auto">
        <button className="btn btn-primary" type="button" onClick={toggleBreakdownType}>
          App Type Breakdown
        </button>
        </div>
        <div className="col-auto">
        <button className="btn btn-primary" type="button" onClick={toggleBreakdownConfig}>
          Config Breakdown
        </button>
        </div>
        <div className="col-auto">
        <button className="btn btn-primary" type="button" onClick={exportDriverList}>
          Export
        </button>
        </div>
        <div className="col-12">
          <AppBreakdown drivers={model.drivers} type={model.breakdownType}/>
        </div>
      </div>

      <div className="row">

        <DriverTable drivers={model.drivers} click={model.setModalDriver}/>

      </div>
      <DriverListActionModal
        driver={model.modalDriver}
        setDriver={model.setModalDriver}
        goToDriverLogsTab={goToDriverLogsTab}
        goToOperatorLogsTab={goToOperatorLogsTab}
        setModifyDriver={showModifyPrompt}
      />
      <DriverModifyModal
        model={modelDriverModify}
        toast={modelAlert.show}
        save={model.search}
      />
      <AlertModal model={modelAlert}/>
    </>
  )

}

function DeviceType({value, setValue}){

  const handleChange = (e) => setValue(e.target.value);

  return (
    <select className="form-control" value={value} onChange={handleChange}>
        <option value="">-- App Type --</option>
        <option value="hubmobile">HubMobile (Any)</option>
        <option value="hubmobileAndroid">HubMobile Android</option>
        <option value="hubmobileIos">HubMobile iOS</option>
        <option value="hubdespatch">HubDespatch (Any)</option>
        <option value="hubdespatchAndroid">HubDespatch Android</option>
        <option value="hubdespatchIos">HubDespatch iOS</option>
        <option value="hubwarehouse">HubWarehouse (Any)</option>
        <option value="hubwarehouseAndroid">HubWarehouse Android</option>
        <option value="hubwarehouseIos">HubWarehouse iOS</option>
        <option value="hubsales">HubSales</option>
      </select>
  );

}

function AppBreakdown({drivers, type}) {

  var key = '';
  if (type === 'version'){
    key = 'd_version';
  }else if (type === 'type'){
    key = 'd_type';
  }else if (type === 'config'){
    key = 'd_configfile';
  }else{
    return (<></>);
  }

  var filtered = {};
  drivers.map(d => d[key])
        .reduce((obj, v) => filtered[v] = (filtered[v] || 0) + 1, {});
  if (type === 'version'){
    filtered.Total = drivers.length;
    filtered.Unknown = filtered[''] || 0;
    delete filtered[''];
  }

  var chips = Object.keys(filtered)
                    .sort() // alphabetical order
                    .map(key => Chip(key, filtered[key]));

  return (
    <>
      <br/>
      {chips}
    </>
  );
}

function Chip(text, count){
  return (
    <span className="badge bg-warning text-dark m-1">
      { text }: { count }
    </span>
  );
}


